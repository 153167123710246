import React from "react";

const Materiels = () => (<div>
    <h1>Matériel à apporter</h1>
    <p>Pour passer un séjour agréable au Grimoire des Odyssées, vous devez apporter certains articles importants. Voici une liste de trucs que vous ne voudriez pas oublier.</p>

    <h2>Votre personnage</h2>
    <p>Pour ceux qui sont déjà venus au Grimoire des Odyssées, assurez-vous d’avoir en main votre livret de personnage. Les joueurs qui en sont à leur première visite, il est plus que recommandé d’avoir un personnage complet à votre arrivée. Un joueur n’ayant aucune connaissance des règles et n’ayant pas son personnage complet retarde le début du jeu. Un créateur de personnage est disponible sur le site internet.</p>

    <h2>Vos costumes</h2>
    <p>Vous avez bien lu, il s’agit bien de plusieurs costumes. En réalité, on vous recommande d’avoir minimalement deux costumes d’allure médiévale. Du moins, avoir quelques accessoires permettant de modifier votre premier costume pour en faire un deuxième. Souvenez-vous que votre personnage n’est pas immortel. Alors il est toujours mieux d’avoir un deuxième costume qui diffère du premier. Prévoyez aussi des vêtements et des bas de rechange, on finit toujours par se mouiller les pieds dans l’herbe humide. De bonnes bottes et une gourde s’avèrent très pratiques.</p>

    <h2>Votre nourriture</h2>
    <p>Il est important d’apporter suffisamment de nourriture et d’eau pour passer une fin de semaine en forêt. Il s’agit en fait de cinq repas, soit deux déjeuners, deux dîners et un souper. L’expérience vous apprendra qu’en grandeur nature, on a plus souvent envie de grignoter que de se lancer dans la grande cuisine en plein air. Idéalement, amenez de la nourriture non périssable et rien qui nécessite d’être gardé au froid. Vous vous éviterez ainsi bien des soucis. Prévoyez beaucoup de liquide, eau et jus.</p>

    <h2>Votre campement</h2>
    <p>Un des aspects le plus importants que vous ne devez pas négliger est votre campement. Apportez le nécessaire pour passer une fin de semaine en camping. L’équipement se résume généralement aux accessoires suivants :</p>

    <ul>
        <li>Tente</li>
        <li>Matelas de sol</li>
        <li>Sac de couchage et oreiller</li>
        <li>Lampe de poche et piles de rechange</li>
        <li>Chasse-moustiques (très important)</li>
    </ul>
    <p>Les torches à l’huile, chandelles où tous autres instruments produisant une flamme sont tolérés uniquement si celles-ci demeurent à au moins cinq mètres de toutes tentes ou autre objet inflammables.</p>

    <h2>Vos armes et armures</h2>
    <p>Vous désirerez très certainement que votre personnage soit armé en terre d'Awa, du moins c’est ce que les villageois vous conseilleront. Les armes et armures que vous apporterez au Grimoire devront être achetées aux marchands avec les écus de départ de votre personnage. L’aspect sécuritaire de toutes vos armes et armures devra être vérifié par un organisateur au début de chaque scénario. Des armes sont disponibles auprès des marchands pour ceux qui n’en auraient pas.</p>

    <p><u>Assurez-vous en tout temps que vos armes respectent les normes suivantes :</u></p>
    <ol>
        <li>Mon arme est construite avec uniquement les matériaux suivants :</li>
        <ul>
            <li>Tige en tuyau de PVC, carbone, fibre de verre à éviter.</li>
            <li>Isolant à tuyaux</li>
            <li>Mousse de sofa</li>
            <li>Ruban adhésif flexible (Duct tape, ruban électrique)</li>
            <li>Latex</li>
            <li>Mousse injecté</li>
        </ul>
        <li>Mon arme est flexible et non cassante</li>
        <li>Mon arme ne contient ni métal ni bois</li>
        <li>La lame, la garde et le pommeau de mon arme sont rembourrés</li>
    </ol>

    <p>Les flèches et carreaux doivent être protégés d’un bouchon de liège. Le bouchon de liège doit être percé jusqu’à sa moitié. La tige doit être collée dans ce trou. On collera également un sou noir (1 ¢) à l’extrémité non percé du bouchon pour éviter que le goujon ne traverse le liège. La pointe de la flèche sera ensuite recouverte de mousse à sofa.</p>
    <p>Il existe désormais des flèches pour grandeurs-natures d’un décorum et d’une efficacité fantastiques.
En ce qui concerne les armures, elles doivent être sécuritaires et ne présenter aucune surface pointue ni coupante. Elles doivent être construites avec de vrais matériaux. On considère que la cuirette peut faire en guise de cuir. Les boucliers pour leur part devront avoir leurs circonférences rembourrées.
</p>
</div>)


export default Materiels;