import React from "react";

const Effets = () => (<div>
    <h1 >Les poisons qui peuvent affectés votre personnage en jeu</h1>
   
    <p>Durant ses aventures, votre personnage pourrait être victime de poison ou encore en infliger les conséquences à ses adversaires. Il existe différentes façons de se procurer du poison ; par achat, par habileté de race ou encore en le fabriquant de vos mains. Chaque poison possède des caractéristiques qui leur sont propres. Leur point en commun est qu’ils prennent tous 20 secondes à agir à 100 %. À partir du moment où la cible est affectée par un poison, nous demandons à ce que le jeu de rôle soit aussi affecté jusqu’à ce que l’effet soit total. Les seules exceptions à la règle des 20 secondes sont les poisons blessants qui possèdent un effet immédiat. </p>
    <p><u>Le poison terrassant</u>: Il prend 20 secondes pour plonger sa victime dans un coma.</p>
    <p><u>Le poison mortel</u>: Ce poison très rare est plus spécial que les autres. Comme le poison terrassant, il prend 20 secondes pour plonger sa victime dans un coma. Par la suite, le poison commence à détruire le corps de l’intérieur. Si aucune action n’est prise, la cible en meurt en 30 minutes, suite à l’exposition de celui-ci.</p>

</div>)


export default Effets;
