import React from "react";
import { Link } from "react-router-dom";

const CreationPerso = () => (<div>

    <h1>Création de personnages</h1>

    <h2>Le personnage</h2>
    <p>Tout joueur au Grimoire des Odyssées vient pour y incarner un personnage. Même si l’essentiel du jeu demeure dans l’interprétation du rôle de celui-ci, un minimum de règles existe pour évaluer ses aptitudes à faire face aux différents défis en Terres d'Awa. Ces règles de création sont expliquées dans les paragraphes qui suivent.
Pour créer ce personnage, vous devez suivre les étapes suivantes.
</p>
    <ol>
        <li><Link to="/races">Choisir une race</Link></li>
        <li><Link to="/competences">Choisir un nombre de compétences selon la race</Link> </li>
        <li><Link to="/magie">Positionner les runes pour évaluer les aptitudes magiques</Link></li>
        <li><Link to="/equipement">Acheter de l’équipement de valeur égale ou moindre à vos écus attribués au départ</Link></li>
    </ol>
    <p>Le personnage ainsi créé aura ses propres caractéristiques. Pour mieux comprendre celles-ci, voici une brève description de chacune :</p>

    <h3>Les races</h3>
    <p>L’univers fabuleux dans lequel Le Grimoire des Odyssées se déroule est peuplé de races fantastiques telles que l’elfe, le saurien, le nain et l’humain. Chacune de celle-ci possède des charactéristiques qui lui est propre. Plus d'information <Link to="/races/">ici</Link></p>

    <h3>Les points d’endurance</h3>
    <p>Les points d’endurance du personnage représentent son aptitude à encaisser des dégâts, on les appelle souvent des points de vie. Sans point d’endurance, un personnage est inerte au sol pour dix minutes. On dit alors qu’il est terrassé.</p>

    <h3>Les indices</h3>
    <p>Les indices sont utilisés pour évaluer les aptitudes générales d’un personnage. Ils influencent ses compétences, ses sortilèges et sa prouesse au combat. Chacun des indices est représenté par un chiffre variant généralement entre un et cinq. </p>
    <p>Il existe six indices divisés en deux catégories, soit trois indices physiques ; la force, la dextérité et la vigueur, ainsi que trois indices mentaux ; le raisonnement, l’introspection et la prestance. </p>
    <p>Chaque personnage débute avec une base dans chaque indice, selon la race qu’il aura choisie. On considère que : </p>
    <ul>
        <li>Indice de 1 : Normal</li>
        <li>Indice de 2 : Moyen</li>
        <li>Indice de 3 : Fort</li>
        <li>Indice de 4 : Remarquable</li>
        <li>Indice de 5 : Exceptionnel</li>
    </ul>
    <p>La magie peut toutefois permettre d’atteindre des indices beaucoup plus élevés encore. Voici une courte explication de chacun des indices ainsi que les talents et habilités reliés à ceux-ci.</p>
    <p>Porter une attention particulière aux compétences que votre personnage veut développer à long terme, soit les talents, puisque celles-ci demandent un certain niveau d’un indice propre à ce talent pour poursuivre sa progression. Exemple : L’Alchimie est un talent qui demande à votre personnage un bon niveau de Raisonnement. Un joueur qui souhaiterait avoir un niveau 3 d’Alchimie, et ainsi réaliser des potions de puissances supérieures devra d’abord s’assurer d’obtenir un indice de Raisonnement égal ou supérieur à 3.</p>

    <h4>Force</h4>
    <p>La force est l’indice physique représentant la puissance musculaire du personnage.</p>
    <p><b>Jeu :</b> Cet indice identifie le potentiel du personnage en ce qui concerne l’accomplissement d’une action directement liée à la puissance musculaire du personnage. </p>
    <p>Cet indice détermine si un personnage peut manipuler une arme de mêlée ou encore s’il peut porter une armure. Voir le tableau des armes et armures.
La force est également l’indice permettant de maîtriser physiquement un personnage ou une créature malgré elle, grâce à l’habileté Maîtrise. Un indice de force de deux est nécessaire pour traîner le corps inerte d’un personnage (Le joueur traînant le corps devra marcher très lentement et avoir les deux mains sur le corps inerte. Il est donc interdit par le personnage inconscient, ainsi que son porteur de tenir leurs armes ou items en mains). Notez que plus d’une personne peut maîtriser ou soulever un personnage ou une créature.
</p>

    <h4>Dextérité </h4>
    <p>La dextérité est l’indice physique représentant l’adresse, l’agilité, la précision, la souplesse et la rapidité du personnage dans ses mouvements. </p>
    <p><b>Jeu :</b> Ainsi, la dextérité déterminera le niveau d’un Talent relié à cet indice. Ainsi que la capacité de sortir d’une maitrise plus base que votre indice de dextérité</p>
    <p>En combat, cet indice détermine aussi si un personnage peut manipuler une arme de jet. Voir le tableau des armes et armures. Cet indice influence également l’efficacité de certaines compétences telle que Subtiliser. </p>

    <h4>Vigueur </h4>
    <p>Représente la constitution et l’endurance physique d’un personnage. La vigueur est également l’indice permettant au personnage d’aller puiser en lui l’énergie nécessaire à l’exécution de certains mouvements spécifiques tels que : Coup puissant, Brise bouclier, Riposte, Parade, désarmement et Duplican.</p>

    <h4>Raisonnement </h4>
    <p>On considère le raisonnement comme la capacité du personnage à approfondir ses connaissances générales. </p>
    <p><b>Jeu : </b>Le personnage ayant beaucoup de raisonnement peut investir davantage dans les talents reliés à cet indice. </p>

    <h4>Introspection </h4>
    <p>L’introspection est l’indice d’un personnage représentant la connaissance de ses forces et de ses limites personnelles, de même que son lien avec l’au-delà. Cet indice influence particulièrement la capacité d’un personnage à puiser et à modeler efficacement les forces magiques divines. </p>
    <p><b>Jeu : </b>L’introspection influence certains sortilèges de magie divine, tels que la guérison et les talents reliés au divin. </p>

    <h4>Prestance </h4>
    <p>La prestance est la manifestation de la personnalité d’un personnage, de son charisme et de son apparence. C’est par la prestance qu’un personnage s’impose aux autres, qu’il les manipule, qu’il les influence ou qu’il les encourage.</p>
    <p><b>Jeu : </b>La prestance influence particulièrement les chants des bardes et le courage.</p>

    <h2>Les compétences</h2>
    <p>Chaque personnage possède des caractéristiques différentes qu’il peut développer au cours de sa vie. Il débute donc avec un nombre de compétences selon la race choisie. Ces compétences de départ doivent être toutes assignées à des habiletés, des talents ou des spécialisations lors de la création d’un personnage. Vous trouverez la liste <Link to="/competences">ici</Link>.</p>

    <h3>Les talents</h3>
    <p>Les talents sont des compétences à développement liées à un indice. Un joueur peut utiliser plusieurs points de compétences pour améliorer davantage un talent, durant ses progressions. Toutefois, le niveau du talent ne peut excéder celui de l’indice associé.</p>
    <p>Par exemple : Ramis le nain, n’ayant qu’un seul point à son indice de Raisonnement, ne pourra pas développer son talent de Premiers soins au niveau 2.</p>

    <h3>Les Techniques</h3>
    <p>Tout comme les talents, les techniques sont des compétences à développer. Un joueur peut acquérir une technique soit en développant un talent (par exemple, Spécialisation : arme lourde) ou en dépensant 3 points de technique dans le niveau de technique désiré. Les techniques sont habituellement reliées au combat, et il est possible de les développer sur trois (3) niveaux.</p>
    <p>Par exemple : Firri, l’elfe commence avec trois (3) points de compétence, il en dépense un pour le talent « Spécialisation :Arme 1 main » . Ce talent, au niveau 1, octroie une technique de son choix. Il choisit la technique « Désarmement ». Lors de ses progressions, Firri choisit les progressions martial, ce qui lui donne un point de technique. Après 3 progressions martial, Firri choisit de prendre le deuxième niveau de désarmement en plus de sa progression normale.</p>

    <h3>Les habiletés</h3>
    <p>Les habiletés, à l’opposé des talents et des techniques, sont des compétences à un seul niveau. Les habiletés de races sont automatiquement attribuées lors de la création de votre personnage. Les compétences d’équipement et de noblesse ne peuvent être prises qu’à la création de personnages.</p>

    <h3>Les compétences en jeu</h3>
    <p>Les personnages pourront acquérir de nouvelles habiletés en accomplissant diverses tâches. Que ce soit lors de la réalisation d’un exploit, d’un évènement en jeu de rôle remarquable ou tout simplement en suivant les enseignements d’un maître.</p>

    <h2>Les runes</h2>
    <p>Tout personnage a en lui un potentiel magique. Les runes servent à représenter les aptitudes d’un personnage à exploiter ce potentiel. Plus un personnage acquiert de runes, plus il est habile avec la magie. Vous trouverez <Link to="/magie">ici</Link> les plus d'information à ce sujet.</p>

    <h2>L'équipement</h2>
    <p>Un nouveau personnage débute avec une richesse de 15 écus qu'il peut utiliser pour s'équiper ou garder en monnaie. Vous pouvez consulter la liste de prix <Link to="/equipement">ici</Link></p>

    <h1>L’évolution en jeu du personnage</h1>
    <p>Au long de ses aventures, un personnage aura la chance d’amasser des runes, des compétences, des points d’endurance et même des indices. Cette évolution en jeu se fait de diverses façons : en terminant une quête, en remportant un combat clé, par l’intermédiaire d’un tuteur ou bien par un jeu de rôle exemplaire, etc. De plus, après un scénario, le joueur obtient une progression.</p>

    <h2>Les progressions</h2>
    <p>À son deuxième scénario, et pour chaque scénario subséquent auquel il participe, le personnage se voit attribuer une progression générale et un choix de progression. Une progression général consiste à :    </p>
    <ul>
        <li>Une compétence</li>
        <li>1 points d’endurance</li>
    </ul>


    <h3>Progression Martiale : </h3>
    <p>1 point d’endurance et un point de technique</p>
    <h3>Progression Runique : </h3>
    <p>Une rune supplémentaire. Le personnage a également le choix de se spécialiser dans un type de manifestation magique. Il doit choisir entre +1 Puissance, +1 de durée ou +1 mètre sur tous les sortilèges. </p>
    <p>Ainsi, le joueur peut choisir de faire évoluer son personnage vers le combat ou encore vers la magie.
       Après trois progressions acquises (donc au début du quatrième, septième, dixième, etc. scénario), le joueur peut ajouter un point à l’indice de son choix. Ce point d’indice supplémentaire ne compte pas comme l’habilité Développement.</p>
    <p>Lorsque le personnage atteint 12 progression, la progression générale ne lui est plus accessible. Cependant, il pourra choisir de perdre une compétence pour en gagner une nouvelle dont il répond aux exigences. Ce qui veut dire que le personnage pourra toujours avoir une progression runique ou martial, mais qu’il ne pourra plus avoir de nouvelle compétence.
    </p>
</div>)


export default CreationPerso;