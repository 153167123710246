import React from "react";

const Apprentissage = () => (<div>
  <h1>Apprentissage</h1>


  <h2>Apprenti</h2>
  <p><u>Cette règle s'applique seulement aux compétences suivante: Alchimiste, Forgeron, Erudit(enchantement)</u></p>
  <p>
    Lorsque qu’un maître de la compétence (niveau 5) aide un apprenti, 25% du temps sera déduit du temps total d’un projet travaillé ensemble 
    (jusqu’à un maximum de 50% de réduction) La présence d’un maître, s’il ne travail pas sur le même projet, diminue le temps de l’apprentie de 10%.  
  </p>

  <h2>Enseignement</h2>
  <p>Cette habileté s’adresse à tous les talents en 5 niveaux et aux métiers avec la compétence maître du métier.
     L’enseignement d’un maître (compétence niveau 5) à un apprenti (compétence niveau 1) Prends 1h, plus 30 minutes par apprentie supplémentaire. Un maître pourra avoir un maximum de 3 élèves par lune et un apprenti ne pourra recevoir plus de 2 cours par lune.
     Les compétences apprises en jeu comptent dans le maximum de 12 progressions générales. 
     Le coût de l’apprentissage d’une compétence coûtera 60 écus en matériaux en lien avec la compétence qui devront être remis à la cabane d’animation (ou un animateur disponible) pour que la compétence soit cochée sur la fiche de personnage. (la compétence forge utilisera des matériaux bruts par exemple, les compétences martiales pourront utiliser des lots d’armement. L’animation acceptera tout explication juger raisonnable.)
  </p>
</div>)


export default Apprentissage;