import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Calendrier from "./Pages/Calendrier";
import Carte from "./Pages/Univers/Carte";
import Chronologie from "./Pages/Univers/Chronologie";
import Competences from "./Pages/Personnage/Competences";
import Divinite from "./Pages/Univers/Divinite";
import Factions from "./Pages/Univers/Factions";
import Ville from "./Pages/Univers/Ville";
import Legendes from "./Pages/Univers/Legendes";
import Header from "./Pages/components/Header";
import Informations from "./Pages/Informations";
import InscriptionEnLigne from "./Pages/Inscription/InscriptionEnLigne";
import Introduction from "./Pages/Introduction";
import Races from "./Pages/Personnage/races";
import Reglements from "./Pages/reglements/Reglements";
import Equipement from "./Pages/reglements/Equipement";
import CreationPerso from "./Pages/reglements/CreationPerso";
import Effets from "./Pages/reglements/Effets";
import Alchimie from "./Pages/reglements/Alchimie";
import Apprentissage from "./Pages/reglements/Apprentissage";
import Reputation from "./Pages/reglements/Reputation";
import Langues from "./Pages/reglements/Langues";
import Magie from "./Pages/reglements/Magie";
import Guilde from "./Pages/reglements/Guilde";
import Materiels from "./Pages/reglements/Materiels";
import Sorts from "./Pages/Personnage/Sorts";
import TopMenu from "./Pages/components/TopMenu";
import PremierPersonnage from "./Pages/Personnage/PremierPersonnage";
import "./style.css";
import "./Utils/Function";
import logo1 from "../src/ressources/logo/banner.png";
import SortPrint from "./Pages/Print/PrintSort";
import ReglePrint from "./Pages/Print/PrintRegle";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <div id="baniere">
            <div style={{ display: "table", margin: "0 auto" }}>
              <a href="/">
                <img id="logo" src={logo1} alt="" />
              </a>
            </div>
          </div>
          <div id="topMenuSection">
            <TopMenu />
          </div>
          <div class="content">
            <Route exact path="/" component={Introduction} />
            <Route path="/competences" component={Competences} />
            {/*<Route path="/triangle" component={Triangle} />
            <Route path="/fiche" component={Fiche} /> */}
            <Route path="/races" component={Races} />

            <Route path="/reglements" component={Reglements} />
            <Route path="/equipement" component={Equipement} />
            <Route path="/creation" component={CreationPerso} />
            <Route path="/effets" component={Effets} />
            <Route path="/langues" component={Langues} />
            <Route path="/magie" component={Magie} />
            <Route path="/alchimie" component={Alchimie} />
            <Route path="/apprentissage" component={Apprentissage} />
            <Route path="/reputation" component={Reputation} />
            <Route path="/guilde" component={Guilde} />
            <Route path="/materiels" component={Materiels} />

            <Route path="/calendrier" component={Calendrier} />
            <Route path="/carte" component={Carte} />
            <Route path="/sorts" component={Sorts} />
            <Route path="/inscription" component={InscriptionEnLigne} />
            <Route path="/informations" component={Informations} />
            <Route path="/factions" component={Factions} />
            <Route path="/legendes" component={Legendes} />
            <Route path="/divinite" component={Divinite} />
            <Route path="/ville" component={Ville} />
            <Route path="/chronologie" component={Chronologie} />
            <Route path="/premierPersonnage" component={PremierPersonnage} />
            <Route path="/printSort" component={SortPrint} />
            <Route path="/printRegle" component={ReglePrint} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
