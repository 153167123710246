import AerisImage from "./../ressources/rune/aeris.png";
import AegisImage from "./../ressources/rune/aegis.png";
import AltraImage from "./../ressources/rune/altra.png";
import BoulemImage from "./../ressources/rune/boulem.png";
import CerclumImage from "./../ressources/rune/cerclum.png";
import CeresImage from "./../ressources/rune/ceres.png";
import ChantaImage from "./../ressources/rune/chanta.png";
import CharasImage from "./../ressources/rune/charas.png";
import ConumImage from "./../ressources/rune/conum.png";
import CratiaImage from "./../ressources/rune/cratia.png";
import CreatumImage from "./../ressources/rune/creatum.png";
import CryosImage from "./../ressources/rune/cryos.png";
import DestraImage from "./../ressources/rune/destra.png";
import ElementisImage from "./../ressources/rune/elementis.png";
import EthasImage from "./../ressources/rune/ethas.png";
import GayasImage from "./../ressources/rune/gayas.png";
import GeosImage from "./../ressources/rune/geos.png";
import IrasImage from "./../ressources/rune/iras.png";
import LinimImage from "./../ressources/rune/linim.png";
import LunarImage from "./../ressources/rune/lunar.png";
import NergisImage from "./../ressources/rune/nergis.png";
import PersomImage from "./../ressources/rune/persom.png";
import ProctaImage from "./../ressources/rune/procta.png";
import PyrosImage from "./../ressources/rune/pyros.png";
import SolarImage from "./../ressources/rune/Solar.png";
import TactimImage from "./../ressources/rune/tactim.png";
import MystemImage from "./../ressources/rune/mystem.png";
import VelumImage from "./../ressources/rune/velum.png";


export function runesMap() {
    return [
        {
            "type": "Celestial",
            "runes": [
                {
                    "name": "Aegis",
                    "image": AegisImage,
                },
                {
                    "name": "Ceres",
                    "image": CeresImage,
                },
                {
                    "name": "Creatum",
                    "image": CreatumImage,
                },
                {
                    "name": "Elementis",
                    "image": ElementisImage,
                },
                {
                    "name": "Iras",
                    "image": IrasImage,
                },
                {
                    "name": "Lunar",
                    "image": LunarImage,
                },
                {
                    "name": "Solar",
                    "image": SolarImage,
                },
                {
                    "name": "Mystem",
                    "image": MystemImage,
                },
                {
                    "name": "Velum",
                    "image": VelumImage,
                }
            ]
        },
        {
            "type": "Composante",
            "runes": [
                {
                    "name": "Aeris",
                    "image": AerisImage,
                },
                {
                    "name": "Charas",
                    "image": CharasImage,
                },
                {
                    "name": "Cryos",
                    "image": CryosImage,
                },
                {
                    "name": "Ethas",
                    "image": EthasImage,
                },
                {
                    "name": "Gayas",
                    "image": GayasImage,
                },
                {
                    "name": "Geos",
                    "image": GeosImage,
                },
                {
                    "name": "Nergis",
                    "image": NergisImage,
                },
                {
                    "name": "Pyros",
                    "image": PyrosImage,
                }
            ]
        },
        {
            "type": "Manifestation",
            "runes": [
                {
                    "name": "Boulem",
                    "image": BoulemImage,
                },
                {
                    "name": "Cerclum",
                    "image": CerclumImage,
                },
                {
                    "name": "Conum",
                    "image": ConumImage,
                },
                {
                    "name": "Linim",
                    "image": LinimImage,
                },
                {
                    "name": "Persom",
                    "image": PersomImage,
                },
                {
                    "name": "Tactim",
                    "image": TactimImage,
                }
            ]
        },
        {
            "type": "Effet",
            "runes": [
                {
                    "name": "Altra",
                    "image": AltraImage,
                },
                {
                    "name": "Chanta",
                    "image": ChantaImage,
                },
                {
                    "name": "Cratia",
                    "image": CratiaImage,
                },
                {
                    "name": "Destra",
                    "image": DestraImage,
                },
                {
                    "name": "Procta",
                    "image": ProctaImage,
                }
            ]
        }
    ]
}

export default Function;
