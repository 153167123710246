import React, { Component } from "react";

import SortData from "./../../data/SortData.json";
import ReactModal from "react-modal";

import { runesImageMap } from "./../../Utils/RuneImageMap";

import BGModal from "./../../ressources/background/background_4.jpg";

const customStyles = {
  content: {
    maxHeight: "550px",
    maxWidth: "1000px",
    height: "auto",
    margin: "auto",
    marginTop: "30px",
    backgroundImage: "url(" + BGModal + ")"
  }
};



class Sorts extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      row: undefined,
      filters: ["", "", "", "", ""],
      sortByColumn: "nom",
      sortOrder: "asc"
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }

  handleOpenModal(rowData) {
    this.setState({ ...this.state, showModal: true, row: rowData });
  }

  handleCloseModal() {
    this.setState({ ...this.state, showModal: false, row: undefined });
  }

  setFilter(idx, value) {
    let state = { ...this.state }
    state.filters[idx] = value;
    this.setState(state);
  }

  getCompValue(txt)
  {
    return txt.toLocaleLowerCase()
              .replace("é", "e")
              .replace("è", "e")
              .replace("ê", "e")
              .replace("à", "a")
              .replace("â", "a")
              .replace("î", "i")
              .replace("ï", "i")
              .replace("ô", "o")
              .replace("ö", "o")
              .replace("û", "u")
              .replace("ü", "u")
              .replace("ç", "c");
  }

  getFilteredSpell()
  {
    return SortData.filter(sort => {
      let filters = this.state.filters;
      filters[1] = this.getCompValue(filters[1]);
      return (filters[0] === "" || sort.type === filters[0]) &&
             (filters[1] === "" || this.getCompValue(sort.nom).includes(filters[1])) &&
             (filters[2] === "" || sort.runes[0] === filters[2]) &&
             (filters[3] === "" || sort.runes[1] === filters[3]) &&
             (filters[4] === "" || sort.runes[2] === filters[4]);
    })
  }

  selectFilterHeader(name, val, idx) {
    return (
      <th>{name}
      <select id={val} onChange={e => this.setFilter(idx, e.target.value) }>
      <option id="-1" value=""> </option>
      {[...new Set(SortData.map(sort => sort[val]))].map((sort, index) => {
        return (
          <option id={index} value={sort}>{sort}</option>
        );
      })}
      </select>
    </th>)
  }

  selectFilterHeaderRune(name, val) {
    return (
      <th>{name}
      <select id={val} onChange={e=>this.setFilter(2+val, e.target.value)} defaultValue="">
      <option id="-1" value=""> </option>
      {[...new Set(SortData.map(sort => sort.runes[val]))].map((rune, index) => {
        return (
          <option id={index} value={rune}>{rune}</option>
        );
      })}
      </select>
    </th>)
  }

  selectTextFilterHeader(name, val, idx) {
    return (
      <th>{name}
      <input id={val} onInput={e => this.setFilter(idx, e.target.value) }/>
    </th>)
  }

  renderImage(runes) {
    let rune1 = runes[0];
    let rune2 = runes[1];
    let rune3 = runes[2];

    return (<div>
      <div>
        <div class="w3-col m4">
          <img class="center" src={runesImageMap(rune1)} alt="" />
        </div>
        <div class="w3-col m4">
          <img class="center" src={runesImageMap(rune2)} alt="" />
        </div>
        <div class="w3-col m4">
          <img class="center" src={runesImageMap(rune3)} alt="" />
        </div>
      </div>
      <div>
        <div class="w3-col m4">
          <p class="w3-center">{rune1}</p>
        </div>
        <div class="w3-col m4 .w3-center">
          <p class="w3-center">{rune2}</p>
        </div>
        <div class="w3-col m4 .w3-center">
          <p class="w3-center">{rune3}</p>
        </div>
      </div>
      <div class="w3-col m12">
        <h2>
          {rune1} {rune2} {rune3}
        </h2>
      </div>
    </div>);
  }

  render() {
    let modal = [];
    if (typeof this.state.row !== "undefined") {

      const runes = this.renderImage( this.state.row.runes);

      modal = (
        <ReactModal style={customStyles} isOpen={this.state.showModal}>
          <div class="w3-col m12">
            <h1>{this.state.row.nom}</h1>
          </div>
          {runes}
          <div class="w3-col m12">
            <p class="w3-col m6">
              <b>Puissance: </b>
              {this.state.row.puissance}
            </p>
            <p class="w3-col m6">
              <b>Portée: </b>
              {this.state.row.portee}
            </p>
          </div>
          <div class="w3-col m12">
            <p class="w3-col m6">
              <b>Cibles: </b>
              {this.state.row.region}
            </p>
            <p class="w3-col m6">
              <b>Durée: </b>
              {this.state.row.duree}
            </p>
          </div>
          <p class="w3-col m12">
            <b>Description: </b>
            {this.state.row.description}
          </p>
          <p class="w3-col m12">
            <b>Note: </b>
            {this.state.row.note}
          </p>
          {this.state.row.armelioration !== true && 
            <p class="w3-col m12">
              <p><b>Note:</b> Ceci est en sort d'amélioration</p>
              {this.state.row.amelioration}
            </p>
          }
          <div class="w3-col m12 w3-right">
            <button
              style={{ marginTop: "20px", float: "right" }}
              onClick={this.handleCloseModal}
            >
              Fermer
            </button>
          </div>
        </ReactModal>
      );
    }
    return (
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              {this.selectFilterHeader("Type", "type", 0)}
              {this.selectTextFilterHeader("Nom", "nom", 1)}
              {this.selectFilterHeaderRune("Composante", 0)}
              {this.selectFilterHeaderRune("Manifestation", 1)}
              {this.selectFilterHeaderRune("Effet", 2)}
            </tr>
          </thead>
          <tbody>
            {this.getFilteredSpell().map((sort, index) => {
              return (
                <tr key={index} onClick={() => this.handleOpenModal(sort)}>
                  <td>{sort.type}</td>
                  <td>{sort.nom}</td>
                  <td>{sort.runes[0]}</td>
                  <td>{sort.runes[1]}</td>
                  <td>{sort.runes[2]}</td>
                </tr>
              );
            })}
           </tbody>
            
        </table>
        
        {modal}
      </div>
    );
  }

}


export default Sorts;
