import React from "react";

const Guilde = () => (<div>
    <h1 class="title">Les guildes et les ordres</h1>
    <p>Optionnellement, il est possible en jeu d’adhérer à un Ordre ou une Guilde. Certaines Guildes et Ordres sont déjà présentes, cependant il est possible pour un nombre de joueurs d’en créer de nouvelles. Si votre personnage est accepté comme membre, il bénéficiera des avantages reliés à l’ordre en question. Adhérer à l’un d’eux n’est pas obligatoire.</p>
    <p>Lorsque plusieurs personnes se rassemblent et forment une Guilde ou un Ordre, ils parviennent à faire des choses incroyables et bien souvent, elles viennent à jouer un rôle majeur dans la géopolitique d'Awa.</p>

    <h2>Les Guildes</h2>
    <p>Une Guilde doit être composée au minimum de 5 membres et être affiliée à une figure d’autorité qui pourra officialiser la guilde sous sa tutelle. La figure d’autorité peut être un baron, un roi, un noble, un conseillé royal ou impérial et même d’autres personnes d’importance. Avant d’être acceptée, une Guilde devra, par écrit, décrire ses fonctions, ses buts, ses valeurs et ses méthodes.</p>

    <h3>Les Membres</h3>
    <p>Les membres sont les piliers des guildes et des ordres. Ils effectuent souvent le travail de terrain. Tous les membres de la Guilde obtiennent le bonus de la guilde. Celui-ci est déterminé à la création de la Guilde ou de l’Ordre.</p>

    <h3>Les Chefs</h3>
    <p>Les chefs dirigent les membres et décident des opérations. Un membre sur 5 peut prétendre au titre de chef et accéder aux bonus liés à ce statut. Donc si une guilde possède dix membres, celle-ci pourra avoir 2 chefs. Tous les chefs obtiennent le bonus lié à leur statut. Celui-ci est déterminé lors de la création de la Guilde ou de l’Ordre.</p>

    <h2>Les Ordres</h2>
    <p>Les Ordres sont des regroupements qui ont fait développer leur réputation et leurs talents. Afin de créer un Ordre, la Guilde doit prouver sa valeur (avoir l’accord de l’animation), augmenter ses rangs jusqu’à au moins 12 membres et avoir l’appui d’un noble prêt à s’occuper des coûts plus élevé lié au maintien d’un ordre. Un ordre gagne un deuxième bonus de Guilde ou une augmentation du premier.</p>

</div>)


export default Guilde;