import React from "react";
import { Table } from "react-bootstrap";

const Informations = () => (
  <div>
    <div>
      <h1>Tarifs</h1>
      <div>
        <Table>
          <tr>
            <th />
            <th>Prix/Scénario</th>
            <th>Passe Saison (4 Scénarios)</th>
          </tr>
          <tr>
            <th>Joueur/Joueuse</th>
            <td>50$</td>
            <td>170$</td>
          </tr>
        </Table>
      </div>

      <h3>Nouveau joueur :</h3>
      <ul>
        <li>
          Si vous êtes un nouveau joueur, vous profitez d'un <b>rabais de 10$</b> sur 
          l'achat de votre premier scénario. (40$ de rabais sur la passe saison)
        </li>
        <li>
          Lorsque vous êtes accompagné d’un nouveau joueur, vous pouvez demander de lancer
          un D20 de bonus qui se reflètera en jeu. Tous les chiffres sont gagnants!
        </li>
      </ul>
    </div>
    <div>
      <h1>Pour nous joindre</h1>
      <p>Pour être au courant des dernières nouvelles, suivez notre page Facebook : <a class="gras" href="https://www.facebook.com/groups/1127357265092216/" target="_blank" rel="noopener noreferrer">Grimoire des Odyssées</a></p>
      <p>Écrivez-nous par Courriel pour toutes informations ou questions : <a class="gras" href="mailto:grimoiredesodyssees@gmail.com" target="_blank" rel="noopener noreferrer">grimoiredesodyssees@gmail.com</a></p>
    </div>
    <div>
      <h1>Lieu</h1>
      <p>
        Le terrain du Grimoire des Odyssées est situé au : 941 rang de la seigneurie, Ste-Émélie-de-l'Énergie, Québec, J0K2K0
      </p>
      <div style={{ textAlign: "center" }}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2755.7141517940627!2d-73.62233062313929!3d46.315514176236064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc600e312c3d0ff%3A0x493accda6379c9b2!2s941%20Rang%20de%20la%20Seigneurie%2C%20Sainte-%C3%89m%C3%A9lie-de-l&#39;%C3%89nergie%2C%20QC%20J0K%202K0!5e0!3m2!1sfr!2sca!4v1743376130352!5m2!1sfr!" 
          width="600"
          height="450"
          frameborder="0"
          style={{ border: "0", margin: "auto" }}
          allowfullscreen
        />
      </div>
    </div>
  </div>
);
export default Informations;