import React from "react";
import Racesdata from "./../../data/racesdata.json";
import { Route, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { space } from "./../../Utils/Function";

import HumainImage from "./../../ressources/Photo/race/humain.jpg";
import ElfeImage from "./../../ressources/Photo/race/elf.png";
import DrowImage from "./../../ressources/Photo/race/drow.png";
import NainImage from "./../../ressources/Photo/race/nain.jpg";
import CyclopeImage from "./../../ressources/Photo/race/cyclope.png";
import SaurienImage from "./../../ressources/Photo/race/saurien.jpg";
import DemiDragonImage from "./../../ressources/Photo/race/demidragon.jpg";
import LycanImage from "./../../ressources/Photo/race/lycan.jpg";
import OrcImage from "./../../ressources/Photo/race/orc.jpg";

function imageRace(nom) {
  switch (nom) {
    case "Humain":
      return HumainImage;
    case "Elfe":
      return ElfeImage;
    case "Drow":
      return DrowImage;
    case "Nain":
      return NainImage;
    case "Cyclope":
      return CyclopeImage;
    case "Saurien":
      return SaurienImage;
    case "Homme-Dragon":
      return DemiDragonImage;
    case "Lycan":
      return LycanImage;
    case "Orc":
      return OrcImage;
    default:
      return;
  }
}

function displayRaces({ match }) {
  const races = Racesdata[match.params.id];

  return (
    <div class="w3-col m9">
      <h2>{races.nom}</h2>
      <div class="w3-col m12">
        <div class="w3-col m6">
          <p>{space(races.description)}</p>
        </div>
        <div class="w3-col m6">
          <img
            src={imageRace(races.nom)}
            alt="img no found"
            style={{
              height: "100%",
              width: "100%",
              paddingLeft: "10px"
            }}
          />
        </div>
      </div>
      <div>
        <div class="w3-col m12">
          <div class="w3-col m6">
            <h3>Indices</h3>
            <p>Force : {races.force}</p>
            <p>Dextérité : {races.dexterite}</p>
            <p>Vigueur : {races.vigueur}</p>
            <p>Raisonnement : {races.raisonnement}</p>
            <p>Introspection : {races.introspection}</p>
            <p>Prestance : {races.prestance}</p>
          </div>
          <div class="w3-col m6">
            <h3>Caractéristique</h3>
            <p>Points d'endurance : {races.vie}</p>
            <p>Nombres de compétences : {races.nbcompetences}</p>
            <p>Rune de composante (première rune d’un sortilège) : {races.rune}</p>
            <p>Avantages : {space(races.avantages)}</p>
            <p>Espérance de vie : {races.esperance}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function MenuRaces({ match }) {
  let html = [];

  for (let index = 0; index < Racesdata.length; index++) {
    const races = Racesdata[index];
    html.push(
      <Link to={`${match.url}/${index}`}>
        <Button style={{ width: "95%" }}>{races.nom}</Button>
      </Link>
    );
  }

  return html;
}

const Races = ({ match }) => (
  <div>
    <div>
      <p>
        Dans l’univers où prend vie l’histoire du Grimoire des Odyssées, les
        joueurs ont le choix de jouer différentes races.
      </p>
    </div>
    <div class="w3-col m3">
      <nav class="menu">
        <h2>Les Races </h2>
        <MenuRaces match={match} />
      </nav>
    </div>
    <div>
      <Route path={`${match.path}/:id`} component={displayRaces} />
      <Route
        exact
        path={match.path}
        render={() => (
          <div class="w3-col m9">
            <h2>Les races en jeu</h2>
            <p>
              Ces races ont bien certainement leurs caractéristiques propres. 
              Certaines font d'agiles archers, d'autres de puissants guerriers. 
              Pour cela, une race débute avec un nombre fixe de points dans les 
              six indices, un nombre de points d'endurance, un nombre de compétences 
              de départ ainsi que des avantages et des contraintes. Également, la 
              race choisie confère une rune d'élément prédéterminée et ainsi qu'une 
              rune de naissance au choix à la création du personnage. Le tableau 
              suivant démontre les particularités des différentes races.
            </p>
          </div>
        )}
      />
    </div>
  </div>
);

export default Races;
