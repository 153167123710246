import React from "react";
import img_excel from "../../../src/ressources/document/logo_document_excel.png";
import createur_excel from "../../../src/ressources/document/Createur.pdf";
import progression_excel from "../../../src/ressources/document/Progression.xlsx";
import pdf_logo from "../../../src/ressources/document/Pdf_icon_file.png";

const InscriptionEnLigne = () => (
  <div>
    <h1>Inscription en Ligne</h1>
    <p>Afin de gagner un maximum de temps lors de l’inscription des joueurs, nous vous offrons la possibilité de nous transmettre en avance votre progression de personnage. Il vous suffit de remplir le document ici-bas et de nous l’envoyer par messagerie au <a class="gras" href="mailto:grimoiredesodyssees@gmail.com" target="_blank" rel="noopener noreferrer">grimoiredesodyssees@gmail.com</a>. Lorsque nous aurons reçu votre progression, nous l’inscrirons d’avance dans votre fiche de personnage. Ainsi, lorsque vous arriverez au terrain, il ne restera que votre entrée à payer pour venir vivre l'expérience Grimoire des Odyssées.</p>
    <p>Le document est fait simplement. Répondez aux questions avec les choix déroulants,dans les cases vides ou en inscrivant du texte. Si vous rencontrez des problèmes, il se peut que votre version d’Excel ne soit pas à jour.</p>
    <p>Merci de nous aider à gagner du temps et à vous offrir la meilleure expérience possible.</p>
    <p>« Toute l’équipe du Grimoire des Odyssées »</p>
    <div>
      <a href={createur_excel} download="Créateur de Personnage"><img src={pdf_logo} style={{ marginLeft: "25px" , width: "8%", height: "8%" }} alt="" /><p>Créateur de Personnage</p></a>
      <a href={progression_excel} download="Progression de Personnage"><img src={img_excel} style={{ width: "13%", height: "13%" }} alt="" /><p>Progression de Personnage</p></a>
    </div>
  </div>
);

export default InscriptionEnLigne;