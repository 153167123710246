import React from "react";

const Calendrier = () => (
    <div style={{ textAlign: "center" }}>
        <h1>Calendrier de la saison 2025</h1>
        <p>Voici les dates de nos évènements :</p>
        <h3>Scénarios</h3>
        <p>Banquent: 5 Avril</p>
        <p>1er: 9-10-11 Mai</p>
        <p>2e : 11-12-13 Juillet</p>
        <p>3e : 29-30-31 Aout</p>
        <p>4e : 26-27-28 Sept</p>
        <p></p>
        <p>On vous invite également à joindre notre groupe facebook pour toute mise à jour et vous tenir au courant : <br/><a class="gras" href="https://www.facebook.com/groups/1127357265092216/" target="_blank" rel="noopener noreferrer">Groupe Facebook - Grimoire des Odyssées</a></p>
    </div>
);
export default Calendrier;
