import React from "react";
import VilleData from "./../../data/etats.json";
import { Route, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { space } from "./../../Utils/Function";

function displayVille({ match }) {
  const ville = VilleData[match.params.id];

  return (
    <div class="w3-col m9">
      <h2>{ville.nom}</h2>
      <div class="w3-col m12">
          <p>{space(ville.resume)}</p>
      </div>
      <div class="w3-col m12">
          <h3>Informations</h3>
          <p>Économie : {ville.economie}</p>
          <p>Population : {ville.population}</p>
          <p>Races : {ville.races}</p>
          <p>Lieu : {ville.continent}</p>
      </div>
    </div>
  );
}

function MenuVille({ match }) {
  let html = [];

  for (let index = 0; index < VilleData.length; index++) {
    const ville = VilleData[index];
    html.push(
      <Link to={`${match.url}/${index}`}>
        <Button style={{ width: "95%" }}>{ville.nom}</Button>
      </Link>
    );
  }

  return html;
}

const ville = ({ match }) => (
  <div>
    <div>
      <p>
        Plusieres villes sont présentes dans l'univers du Grimoire des Odyssées.
      </p>
    </div>
    <div class="w3-col m3">
      <nav class="menu">
        <h2>Les villes</h2>
        <MenuVille match={match} />
      </nav>
    </div>
    <div>
      <Route path={`${match.path}/:id`} component={displayVille} />
      <Route
        exact
        path={match.path}
        render={() => (
          <div class="w3-col m9">
            <h2>Les villes</h2>
            <p>
              L'aventure a lieu a Élysia, une ville portuaire du continent.
              Il est toutefois possible de faire affaire et d'avoir de l'influence dans d'autres villes.
            </p>
          </div>
        )}
      />
    </div>
  </div>
);

export default ville;
